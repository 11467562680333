import React from "react";

import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css"; // Add css for snow theme
import { Container, Grid } from "@mui/material";
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

export function MyEditorS(props)  {
  const {editor}=props
  const placeholder = 'Start writing inner content here...';
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
  
      [{ list: 'ordered'}, { list: 'bullet' }],
      [{ indent: '-1'}, { indent: '+1' }],
  
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link','image','video'],
      [{ color: [] }, { background: [] }],
      
  
      ['clean'],
    ],
  };
  const { quill, quillRef } = useQuill({modules,placeholder});


  


  React.useEffect(() => {
    if (quill) {
      if(editor){
        quill.clipboard.dangerouslyPasteHTML(editor)
      }
      quill.on('text-change', (delta, oldDelta, source) => {
        props.quillChange(quill.root.innerHTML)
        // console.log('Text change!');
        // // console.log(quill.getText()); // Get text only
        // console.log(quill.getContents().ops.filter((val,idx)=>val.insert.image)); // Get delta contents
        // console.log(quill.root.innerHTML); // Get innerHTML using quill
        // // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
      });
    }
  }, [quill,editor]);





  // console.log(quill); // undefined > Quill Object
  // console.log(quillRef); // { current: undefined } > { current: Quill Editor Reference }

  return (
    <Container>
    <div style={{ width:'auto', height: 400 ,marginBottom:50,paddingBottom:50}}>
      <div ref={quillRef} />
    </div>
    </Container>
  );
};
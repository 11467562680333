import { Button, Container, Grid, TextField } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Typography } from '@mui/material';import React, { useState } from "react";
import './blogsMain.css'
import CircularProgress from '@mui/material/CircularProgress';

import { Helmet } from "react-helmet";
import D6_Home_SCarousel from "./Home_MainCrousel/home_crousel";


export default function BlogsMain(props) {

const [search,setSearch]=useState('')

  const {blogData,tagData,adv}=props
  if(blogData&&blogData[0]&&tagData&&tagData[0]){

    
  return (
      <div>
        <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        {tagData.filter((val)=>val.category.includes('meta-title-blog')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    <title>{valB.value}</title>)})}
       
        {tagData.filter((val)=>val.category.includes('meta-description-blog')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
        <meta
          name="description"
          content={valB.value}
        />)})} 

{tagData.filter((val)=>val.category.includes('meta-keywords-blog')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    <meta name="keywords" content={valB.value} />
    )})} 

        {/* <!-- Google / Search Engine Tags --> */}
       
        {tagData.filter((val)=>val.category.includes('meta-title-blog')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
        <meta itemprop="name" content={valB.value}
         />
        
        )})} 
        {tagData.filter((val)=>val.category.includes('meta-description-blog')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    <meta
    itemprop="description"
    content={valB.value}
  />)})} 
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        <meta property="og:url" content="https://gettraveltips.com/" />
  
        <meta property="og:type" content="website" />
        {tagData.filter((val)=>val.category.includes('meta-title-blog')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
        <meta property="og:title" content={valB.value} />
        )})} 
        {tagData.filter((val)=>val.category.includes('meta-description-blog')).sort((a,b)=>a.id<b.id?-1:1).map((valB,idx)=>{
  return(
    <meta
          property="og:description"
          content={valB.value}
        />)})} 
        
      
      

      
       
      </Helmet>
     
    <Container style={{maxWidth:1280,marginTop:'15vh'}}>


<Grid container alignItems={'center'}>

  <Grid item  textAlign='center' sm={9} xs={9}>
    <TextField  style={{width:'70%' ,margin:'2vh 0px',textAlign:'center',padding:'0px 5px'}} placeholder="Search here.."  onChange={(e)=>setSearch(e.target.value)} />
  </Grid>
  <Grid item  sm={3} xs={3}>
    <Button disabled={!search?true:false}  onClick={()=>window.location.href=`/search?q=${search}`} style={{backgroundColor:'black',color:'white',fontWeight:'600'}}>SEARCH</Button>
  </Grid>
</Grid>

    <D6_Home_SCarousel blogData={blogData}/>

  
   
      <Grid container style={{paddingTop:30,paddingBottom:30}}> 
     
        <Grid  container className="grid_blog" style={{cursor:'pointer'}} sm={8} xs={12}>
      
          {blogData.filter((val,idx)=>val.active===true).sort((a,b)=>a.views<b.views?1:-1).map((val1,idx)=>{
  return(
    <Grid onClick={()=>window.location.href=`/${val1.urlName}`}  key={idx} container sm={6} xs={12} style={{marginBottom:40}}>
          <Grid item style={{textAlign:'-webkit-center'}} sm={12} xs={12}>
          <Card sx={{ maxWidth: 345 }}>
<CardActionArea>
<CardMedia
component="img"
height="220"
image={val1.image}
alt="green iguana"
/>

</CardActionArea>
</Card>
          </Grid>
          <Grid className="value_grid" item sm={12} xs={12}>
          <Grid item sm={12} xs={12}>
            <Typography className="heading_Q">{val1.valueHead}</Typography>
            
            </Grid>
            <Grid container style={{paddingTop:20}} sm={12} xs={12}>
              <Grid container style={{alignItems:'center'}} sm={12} xs={12}> 
              <i class="far fa-user"></i>
            <Typography style={{paddingLeft:5,paddingBottom:5}} >{val1.createdBy}</Typography> 
              </Grid>
              <Grid  container style={{alignItems:'center'}} sm={6} xs={6}> 
              <i class="far fa-clock"></i>
            <Typography style={{paddingLeft:5}} >{val1.createdOn} </Typography> 
       </Grid>
              <Grid container style={{alignItems:'center'}}  sm={6} xs={6}>
              <i class="fas fa-eye"></i>
            <Typography style={{paddingLeft:5}} >{val1.views} </Typography> 
  </Grid>
            
            </Grid>
            <Grid  item style={{alignItems:'center'}} sm={12} xs={12}>
              <Typography className="heading_A">{val1.viewHead}</Typography>
            </Grid>

            <Grid  container style={{alignItems:'center',justifyContent:'end',paddingTop:20,paddingRight:20}} sm={12} xs={12}>
              <Typography  className="read_more">Read more</Typography>
              <i class="fas fa-angle-double-right"></i>
            </Grid>

         
          
          </Grid>
          </Grid>
  )
  })} 
        </Grid>

        <Grid className="small_grid" container sm={4} xs={12}>
          <Grid container  >
          {adv.filter((val,idx)=>val.site.includes('FM_Blogs1')).map((val1,idx)=>{
  return(
    
          <Grid item sm={12} xs={12}>

            <Card onClick={()=>window.open(val1.url,'_blank')} sx={{ maxWidth: '100%' }} >
            {/* <Card onClick={()=>window.location.replace(val1.url,'_blank')} sx={{ maxWidth: '100%' }} >   */}
<CardActionArea>
<CardMedia
component="img"
height="250"
image={val1.imageName}
alt="green iguana"
/>

</CardActionArea>
</Card>  
            </Grid>
             )
            })} 
        <Grid container style={{justifyContent:'center',alignItems:'center'}} sm={12} xs={12}>
        <Typography className="most_popular">MOST POPULAR</Typography>
        <i class="fas fa-bell"></i>
        </Grid>
        {blogData.filter((val,idx)=>(val.active===true&&idx<6)).sort((a,b)=>a.views<b.views?1:-1).map((val1,idx)=>{
  return(
        <Grid  key={idx} onClick={()=>window.location.href=`${val1.urlName}`}  container style={{marginBottom:30,cursor:'pointer'}} className='small_gridM'  sm={12} xs={12}>
        <Grid item style={{textAlign:'-webkit-center'}} sm={5} xs={12}>
          <Card sx={{ maxWidth: 205 }}>
<CardActionArea>
<CardMedia
component="img"
height="120"
image={val1.image}
alt="green iguana"
/>

</CardActionArea>
</Card>
          </Grid>
          <Grid item style={{textAlign:'center'}} sm={7} xs={12}>
 <Grid item sm={12} xs={12}>
   <Typography className="most_popularT">
   {val1.valueHead}
   </Typography>
 </Grid>
 <Grid item sm={12} xs={12}>
   <Typography className="most_popularV">
   <b>Views:</b>{val1.views}
   </Typography>
 </Grid>
          </Grid>
        </Grid>)
  })} 
       
        </Grid>
        
        </Grid>
      </Grid>
    </Container>
    {/* <MyEditorS /> */}
    </div>)}
    else{
      return(
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>
        <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh'}} />
        </Grid>)
    }
}

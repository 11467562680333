
export const ContactData=[
    {
        id:'1',
        
       
        value:require('../../images/business.jpg'),
        category:'BackgroundImageContact',
        type:'image',
        
        parent:'',
        parent2:'',
      
    },{
        id:'2',
        value:'Get Your Custom Plan Quote',
        category:'BackgroundImageHeading',
        type:'text',
        
        parent:'',
        parent2:'',
    },
    {
        id:'3',
        value:'Pay only when your ads perform!',
        category:'BackgroundImageText',
        type:'text',
        
        parent:'1',
        parent2:'',
    },
    {
        id:'4',
        value:'Different ways to find customers, increase earnings, and optimize your budget!',
        category:'BackgroundImageText',
        type:'text',
        
        parent:'1',
        parent2:'',
        
    },{
        id:'5',
        value:'Turn your partnership into profits. Join the affiliate programs with FillyMedia and start making money!',
        category:'BackgroundImageText',
        type:'text',
        
        parent:'1',
        parent2:'',
        
    },
    {
        id:'6',
        category:'meta-description',
        value:'Turn your partnership into profits. Join the affiliate programs with FillyMedia and start making money!',
        type:'text',
        
        parent:'',
        parent2:'',
        
    },
    {
        id:'7',
        category:'meta-keywords',
        value:'Online discount codes, latest coupon codes, Discount Offers, Voucher codes, Online Discount coupons, latest offers in india, food coupons, Recharge offers, Flight offers',
        type:'text',
        
        parent:'6',
        parent2:'',
        
    }, {
        id:'8',
        category:'meta-google-description',
        value:'Articles, tips, and insights aimed at making you a world-class developer, designer, and beyond.',
        type:'text',
        
        parent:'',
        parent2:'',
        
    },
    {
        id:'9',
        category:'meta-facebook-description',
        value:'Online discount codes, latest coupon codes, Discount Offers, Voucher codes, Online Discount coupons, latest offers in india, food coupons, Recharge offers, Flight offers',
        type:'text',
        
        parent:'',
        parent2:'',
        
    },


    
]
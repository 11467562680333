import { Grid, Typography } from '@mui/material'
import React from 'react'
import './search.css'
export default function Search() {

    const urlParams = new URLSearchParams(window.location.search);

    const search =urlParams.get('q')
  return (
   <Grid container style={{marginTop:'20vh',minHeight:'80vh'}}>
<Grid item sm={12} xs={12}>
    <Typography className='search_results_head'>
        No results found for  <b>{search}</b>
    </Typography>
</Grid>
   </Grid>
  )
}

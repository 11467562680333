import React, {useContext ,useState} from 'react'
import   {BoxContainer,MutedLink,SubmitButton} from './D6_FE_CommonElements';
import { Marginer } from "./Marginer";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import './D6_FE_LoginForm_CSS.css'
import { sha256 } from 'js-sha256';
import {useCookies} from 'react-cookie'
import { apilink } from '../../../App';
import { AccountContext } from "./accountContext";



function D6_FE_LoginForm(props)  {
  const[processing,setProcessing]=useState(null)
  const[auth,setAuth]=useCookies(['auth-fm'])

   
      const [email,setEmail]=useState('')
      const[password,setPassword]=useState('')
      const[label,setLabel]=useState(null)
      var hash=sha256(password)
   const Password=(val)=>{
     setLabel(null)
     setPassword(val)
   
     
   }
 

    const handleSubmit = () => {
      setProcessing('true')
     

        fetch(apilink+'fm-gate',
        //  fetch(' http://127.0.0.1:8000/member',

        {
        method: 'POST',
        headers:{
          'Content-Type' :'application/json'  
        },
        body: JSON.stringify({username:email, passwd:hash})
      })
      .then(res => res.json())
      .then((resp)=>loginRes(resp))  
    
    //   setAuth('auth-fm','assscvcdvd',{
    //     maxAge: 3600 ,
    //     expires:0,
    //     path:'/'
    //  })

    }
    

    const loginRes=data=>{
      setProcessing(null)

      if(data.detail==='Authenticated'){
        setProcessing(null)
        
        setAuth('auth-fm',sha256(data.detail),{
          maxAge: 3600 ,
          expires:0,
          path:'/'
       })
      }
    else{
      setLabel(data.detail)
    }
  

     
   }

    
   
    return (
      <BoxContainer>
       
          <div className="SignIn_Form_Div">
            <ValidatorForm
              //  useRef="form"
                onSubmit={handleSubmit}
               
            >

            <div className="D6_SIForm_TV_Div">
              <label className="D6_SIForm_Label">Username<span className="mandaotry">*</span></label>
              <TextValidator
                      label=""
                      onChange={(e)=>setEmail(e.target.value)}
                      className="D6_SIForm_errorf"
                      name=""
                      value={email}     
                      validators={['required']}
                      errorMessages={['Username is required']}
                  />
            </div>

            <div className="D6_SIForm_TV_Div">
                <label className="D6_SIForm_Label">Password<span className="mandaotry">*</span></label>
               <TextValidator
                    label=""
                    value={password}     
                    className="D6_SIForm_errorf"
                    onChange={(e)=>Password(e.target.value)}
                    name="password"
                    type="password"
                    validators={['required']}
                    errorMessages={['Password is required']}
                   
                />
            </div>
            {/* {(!label||!email?null:label=='Invalid Password'||label=='User Doesnot Exsist'?<label className="D6_SIForm_error">Invalid Credentials</label>:null)} */}
            {(!label?null:<label className="D6_SIForm_error">{label}</label>)}

           
          
          <Marginer direction="vertical" margin="0.8em" />
          <SubmitButton disabled={processing} type="submit">{!processing?'Sign in':'Processing'}</SubmitButton>
          <Marginer direction="vertical" margin="0.5em" />
          {/* <SmallText1>On SignUp, you agree to accept our terms and privacy policy.</SmallText1> */}
        

          </ValidatorForm>
        </div>
      </BoxContainer>
    );
    }
    export default D6_FE_LoginForm;

  
import './D6_FE_AccountBox_CSS.css';
// import '../../../../MainCSS.css';
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import D6_FE_LoginForm from "./D6_FE_LoginForm";
import { motion } from "framer-motion";
import { AccountContext } from "./accountContext";

import {useCookies} from 'react-cookie'
//import D6_FE_SignUpForm from './D6_FE_SignUpForm';


const BoxContainer = styled.div`
  width: 320px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 19px;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
  font-family: var(--fontfamily);
  //position:absolute;
  // top:10px;
  margin-top:  15px;
  margin-bottom: 15px;
  // left: 50%;
   margin-left: 15px;
   margin-right: 15px;

`;

const TopContainer = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1.2em;
  padding-bottom: 5em;
`;

const BackDrop = styled(motion.div)`
  width: 160%;
  height: 550px;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  transform: rotate(60deg);
  top: -285px;
  left: -115px;
  background: rgb(241, 196, 15);
  background: linear-gradient(
    58deg,
    #52B9D1 40%,
    #52B9D1 80%
    
  );
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.h2`
  font-size: 26px !important;
  font-weight: 600 !important;
  line-height: 1.24 !important;
  color: #fff !important;
  z-index: 10 !important;
  padding: 0 !important;
  
`;

export const SmallText = styled.h5`
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  z-index: 10;
  margin: 0;
  margin-top: 7px;
`;
export const SmallText1 = styled.h5`
  color: #52B9D1;
  font-weight: 500;
  font-size: 0.8rem;
  z-index: 10;
  margin: 0;
  margin-top: 7px;
  margin-bottom: 5px;
  text-align: center;
`;
const SmallerText = styled.h6`
  color: #fff;
  font-weight: 500;
  font-size: 0.8rem;
  z-index: 10;
  margin: 0;
  margin-top: 7px;
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.8em;
  min-height: 250px;
`;

const backdropVariants = {
  expanded: {
    width: "233%",
    height: "1050px",
    borderRadius: "20%",
    transform: "rotate(60deg)",
  },
  collapsed: {
    width: "160%",
    height: "550px",
    borderRadius: "50%",
    transform: "rotate(60deg)",
  },
};

const expandingTransition = {
  type: "spring",
  duration: 2.3,
  stiffness: 30,
};

function D6_FE_AccountBox(props) {



  const [isExpanded, setExpanded] = useState(false);
  const [active, setActive] = useState("signin");


  const[screen,setScreen]=useState('')
  const[auth,setAuth]=useCookies(['auth-fm'])



  const[admin,setAdmin]=useState(null)
  useEffect(()=>{
    if(!auth.auth)
    setAdmin(null)
},[auth.auth])


  const playExpandingAnimation = () => {
    setExpanded(true);
    setTimeout(() => {
      setExpanded(false);
    }, expandingTransition.duration * 1000 - 1500);
  };

 

  const switchToSignin = () => {
    playExpandingAnimation();
    setTimeout(() => {
      setActive("signin");
    }, 400);
  };
 

  
   
 

  const contextValue = { switchToSignin    };
 
  return (
    <div className="Div1">
    <AccountContext.Provider value={contextValue}  >
      <BoxContainer >
        <TopContainer>
          <BackDrop
            initial={false}
            animate={isExpanded ? "expanded" : "collapsed"}
            variants={backdropVariants}
            transition={expandingTransition}
          />
          {active === "signin" && (
            <HeaderContainer>
              <HeaderText>Welcome</HeaderText>
              <HeaderText></HeaderText>
              <SmallText>Please sign in to continue!</SmallText>
            </HeaderContainer>
          )} 
        
  
        
        </TopContainer>
        <InnerContainer>
          {active === "signin" && <D6_FE_LoginForm  />}   
        </InnerContainer>
      </BoxContainer>
    </AccountContext.Provider>
    </div>
  );
}


export default D6_FE_AccountBox;